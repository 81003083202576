import { BehaviorSubject } from 'rxjs';
import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background, Text } from '../object';
import { Font } from '../object/font/font.class';
import { Image } from '../object/image/image.class';
import { MultipleLineText } from '../object/multiple-line-text/multiple-line-text.class';
import { WinningList } from '../object/winning-list/winning-list.class';
import { $ } from '../signal';

export class SpinnerFilmstrip extends Filmstrip {
  public name: string = '幸運轉盤';

  public override data: {} = {};

  public override readonly type = FilmstripType.SPINNER;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({ type: 'COLOR', hex: '#FFCDCD' }),

    award: new Text({ placeholder: '獎品名稱...', align: 'center' }),
    winnersInfo: {
      count: 6,
      font: new Font({ align: 'center' }),
    },
    winnerName: new Font({ align: 'center' }),
    attendeesCount: new Font({ backgroundColor: '#FFFFFF' }),
    arrowImage: new Image({
      title: '指針',
      image: '/assets/spinner/arrow.png',
      width: 100,
      height: 60,
      fileFormats: ['png'],
    }),
    wheel: new Image({
      title: '轉盤外框',
      image: '/assets/spinner/wheel.png',
      width: 1000,
      height: 1000,
      fileFormats: ['png'],
    }),
    palette: $.signal(['#FFFFFF', '#FF502A']),
    center: new Image({
      title: '轉盤外框',
      image: '/assets/spinner/center.png',
      width: 150,
      height: 150,
      fileFormats: ['png'],
    }),
  };

  public mobile = {
    background: new Background({ type: 'COLOR', hex: '#D0D9E4' }),
    title: new Text({ content: '恭喜中獎！', align: 'center', bolder: true }),
    card: {
      image: new Image({
        title: '獎品圖片',
        image:
          'https://gosubarpublic.blob.core.windows.net/static/2023/12/05/3111/upload/164657_68df956c-8f18-4420-8233-4f9415b249e8.jpg',
        width: 300,
        height: 200,
        fileFormats: ['jpeg', 'png'],
      }),
      useDefaultImage: $.signal(true),
      title: new Text({ content: '恭喜中獎', bolder: true }),
      content: new MultipleLineText({
        content: '請前往櫃檯服務處，兌換禮物！',
      }),
    },
    spinText: new Text({ content: '轉吧轉吧～', align: 'center' }),
    noAwardText: new Text({ content: '未中獎', align: 'center', bolder: true }),
  };

  public override buttons = [
    {
      label: '啟動轉盤',
      required$: new BehaviorSubject(true),
      once: true,
      method: 'spin',
      position: 'right',
      icon: 'support',
      iconPosition: 'left',
      isExecuted$: new BehaviorSubject(false),
      disabled$: new BehaviorSubject(false),
    },
  ];

  public mobileToolbar = {
    options: ['旋轉中', '中獎', '未中獎'],
    selectedOptionIndex: 0,
  };
}

export class SpinnerModule extends Module {
  public static override moduleName = '幸運轉盤';

  public override name = '幸運轉盤';

  public override type = ModuleType.SPINNER;

  public override filmstrips = [new SpinnerFilmstrip()];

  public override showInCreateDialogOther = false;

  public override data = {
    winningList: new WinningList(),
  };

  public constructor(module: Partial<SpinnerModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
