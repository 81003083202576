import { BehaviorSubject } from 'rxjs';
import { Filmstrip } from '../../abstract/filmstrip.class';
import { FilmstripType } from '../../abstract/filmstrip.type';
import { Background } from '../../object';
import { Font } from '../../object/font/font.class';
import { Text } from '../../object/text/text.class';
import { $ } from '../../signal';

export class CountMarioSection3Count extends Filmstrip {
  public name = '數隻遊戲';

  public override readonly type = FilmstripType.COUNT_MARIO_SECTION_3_COUNT;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/count.jpg',
    }),
    attendeesCount: new Font(),
    count: new Font({ align: 'center' }),
  };

  public mobile = {
    background: new Background({ hex: '#D0D9E4' }),
    text: new Text({ content: '你要數幾隻？', align: 'center', bolder: true }),
    image: $.signal(
      'https://gosubarpublic.blob.core.windows.net/static/2024/03/25/6817/upload/133451_e6a78aa0-4d82-495f-a7dc-48e9cd1169fe.png',
    ),
    waitText: new Font({ align: 'center' }),
  };

  public data = {};

  public override buttons = [
    {
      label: '開始數隻',
      required$: new BehaviorSubject(true),
      once: true,
      method: 'countdown',
      position: 'right',
      icon: 'back_hand',
      iconPosition: 'left',
      isExecuted$: new BehaviorSubject(false),
      disabled$: new BehaviorSubject(false),
    },
  ];

  public mobileToolbar = {
    options: ['數隻', '等待'],
    selectedOptionIndex: 0,
  };
}
